<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col>
        <b-form-group
          label="Hızlı Arama"
          label-for="keyword"
        >
          <b-input-group>
            <b-form-input
              id="keyword"
              v-model="filterParams.keyword"
              placeholder="Parça No, Parça Adı, Plaka, Müşteri Adı..."
              @keydown.enter="getDataList"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="getDataList"
              >
                <FeatherIcon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="auto"
        class="pt-1"
      >
        <b-form-checkbox
          v-model="filterParams.detailSearch"
          switch
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
      <template v-if="filterParams.detailSearch">
        <b-col cols="12">
          <hr>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Marka"
            label-for="id_com_brand"
          >
            <v-select
              id="id_com_brand"
              v-model="filterParams.id_com_brand"
              :options="brands"
              :reduce="item => item.id"
              label="title"
              placeholder="Marka"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Sipariş Tipi"
            label-for="id_com_sparepart_order_type"
          >
            <v-select
              id="id_com_sparepart_order_type"
              v-model="filterParams.id_com_sparepart_order_type"
              placeholder="Sipariş Tipi"
              :options="orderTypes"
              :reduce="item => item.id"
              label="title"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Lokasyon"
            label-for="id_com_location"
          >
            <v-select
              id="id_com_location"
              v-model="filterParams.id_com_location"
              placeholder="Lokasyon"
              :options="locations"
              :reduce="item => item.id"
              label="title"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Departman"
            label-for="id_com_department"
          >
            <v-select
              id="id_com_department"
              v-model="filterParams.id_com_department"
              placeholder="Departman"
              :options="departments"
              :reduce="item => item.id"
              label="title"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Sipariş Tarihi"
            label-for="order_dates"
          >
            <b-input-group id="order_dates">
              <b-form-datepicker
                v-model="filterParams.order_sdate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin',dateFormatOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append is-text>
                -
              </b-input-group-append>
              <b-form-datepicker
                v-model="filterParams.order_edate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin',dateFormatOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append v-if="filterParams.order_sdate || filterParams.order_edate">
                <b-button
                  variant="warning"
                  @click="clearDates('order')"
                >
                  <FeatherIcon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Tahmini Geliş Tarihi"
            label-for="arrival_dates"
          >
            <b-input-group id="arrival_dates">
              <b-form-datepicker
                v-model="filterParams.arrival_sdate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin',dateFormatOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append is-text>
                -
              </b-input-group-append>
              <b-form-datepicker
                v-model="filterParams.arrival_edate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin',dateFormatOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append v-if="filterParams.arrival_sdate || filterParams.arrival_edate">
                <b-button
                  variant="warning"
                  @click="clearDates('arrival')"
                >
                  <FeatherIcon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Parça Geliş Tarihi"
            label-for="completed_dates"
          >
            <b-input-group id="completed_dates">
              <b-form-datepicker
                v-model="filterParams.completed_sdate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin',dateFormatOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append is-text>
                -
              </b-input-group-append>
              <b-form-datepicker
                v-model="filterParams.completed_edate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin',dateFormatOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append v-if="filterParams.completed_sdate || filterParams.completed_edate">
                <b-button
                  variant="warning"
                  @click="clearDates('completed')"
                >
                  <FeatherIcon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="text-center mb-2"
        >
          <b-button
            variant="primary"
            @click="getDataList"
          >
            <FeatherIcon icon="FilterIcon" /> Detaylı Arama
          </b-button>
        </b-col>
      </template>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BFormCheckbox, BInputGroup, BInputGroupAppend, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'OrderFilterSearch',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
  },
  props: {
    getDataList: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterParams() {
      return this.$store.getters['sparepartOrderLines/filterParams']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    orderTypes() {
      return this.$store.getters['sparepartOrderTypes/getSparepart_order_types']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
  },
  created() {
    this.getBrands()
    this.getOrderTypes()
    this.getLocations()
    this.getDepartments()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {
          'com_brand.servicestatus': 1,
        },
      })
    },
    getOrderTypes() {
      this.$store.dispatch('sparepartOrderTypes/sparepart_order_typesList', {
        select: [
          'com_sparepart_order_type.id AS id',
          'com_sparepart_order_type.title AS title',
        ],
      })
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: [
          'com_location.id AS id',
          'com_location.title AS title',
        ],
        where: {
          'com_location.service_status': 1,
        },
      })
    },
    getDepartments() {
      this.$store.dispatch('departments/departmentsList', {
        select: [
          'com_department.id AS id',
          'com_department.title AS title',
        ],
        where: {
          'com_department.type': 1,
        },
      })
    },
    clearDates(type) {
      if (type === 'order') {
        this.filterParams.order_sdate = null
        this.filterParams.order_edate = null
      }
      if (type === 'arrival') {
        this.filterParams.arrival_sdate = null
        this.filterParams.arrival_edate = null
      }
      if (type === 'completed') {
        this.filterParams.completed_sdate = null
        this.filterParams.completed_edate = null
      }
    },
  },
}
</script>
