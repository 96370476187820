<template>
  <div>
    <filter-search :get-data-list="getDataList" />
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      bordered
    >
      <template #cell(work_no)="data">
        <div v-if="data.item.work_no">
          <small class="text-warning">{{ data.item.work_no }}</small>
        </div>
        {{ data.item.company_name ? data.item.company_name : data.item.customer }}
        <div>
          <b-badge
            v-if="data.item.license_plate"
            variant="light-primary"
            class="mr-1"
          >
            <feather-icon icon="CreditCardIcon" /> {{ data.item.license_plate }}
          </b-badge>
          <b-badge
            v-if="data.item.brand"
            variant="light-warning"
            class="mr-1"
          >
            <feather-icon icon="BoxIcon" /> {{ data.item.brand }}
          </b-badge>
          <b-badge
            v-if="data.item.department"
            variant="light-info"
            class="mr-1"
          >
            <feather-icon icon="FolderIcon" /> {{ data.item.department }}
          </b-badge>
        </div>
      </template>
      <template #cell(username)="data">
        {{ data.item.username }}
        <div
          v-if="data.item.created"
          class="d-flex justify-content-center align-items-center"
        >
          <small class="text-muted">
            <feather-icon icon="SaveIcon" />
            {{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}
          </small>
        </div>
        <div
          v-if="data.item.modified"
          class="d-flex justify-content-center align-items-center"
        >
          <small class="text-warning">
            <feather-icon icon="EditIcon" />
            {{ moment(data.item.modified).format('DD.MM.YYYY HH:mm:ss') }}
          </small>
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            :to="$route.path + '/edit/' + data.item.id"
            variant="outline-primary"
            size="sm"
            class="mr-1"
          >
            <feather-icon icon="SearchIcon" />
            Görüntüle
          </b-button>
          <b-button
            v-if="userData.id_com_user_type === '1'"
            variant="outline-danger"
            size="sm"
            @click="removeData(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
            Sil
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BBadge, BButton, BCardFooter, BPagination, BTable, BCardBody,
} from 'bootstrap-vue'
import FilterSearch from '@/views/Spareparts/Orders/Index/FilterSearch.vue'

const tableName = 'com_sparepart_orders'
export default {
  name: 'OrderIndex',
  components: {
    FilterSearch,
    BBadge,
    BCardFooter,
    BButton,
    BTable,
    BPagination,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'work_no',
          label: 'Sipariş',
        },
        {
          key: 'username',
          label: 'Danışman',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.work_no AS work_no`,
          `${tableName}.created AS created`,
          `${tableName}.modified AS modified`,
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_cars.license_plate AS license_plate',
          'com_brand.name AS brand',
          'com_department.title AS department',
          'com_user.name AS username',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['sparepartOrders/dataList']
    },
    dataCounts() {
      return this.$store.getters['sparepartOrders/dataCounts']
    },
    saveData() {
      return this.$store.getters['sparepartOrders/dataSaveStatus']
    },
    filterParams() {
      return this.$store.getters['sparepartOrders/filterParams']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.dataQuery.or_like = {}
      if (this.filterParams.keyword) {
        this.dataQuery.or_like = {
          'com_sparepart_orders.work_no': this.filterParams.keyword,
          'com_cars.license_plate': this.filterParams.keyword,
          'com_customer.name': this.filterParams.keyword,
          'com_customer.company_name': this.filterParams.keyword,
        }
      }
      if (this.filterParams.id_com_brand) {
        this.dataQuery.where['com_sparepart_orders.id_com_brand'] = this.filterParams.id_com_brand
      }
      if (this.filterParams.id_com_sparepart_order_type) {
        this.dataQuery.where['com_sparepart_orders.id_com_sparepart_order_type'] = this.filterParams.id_com_sparepart_order_type
      }
      if (this.filterParams.id_com_location) {
        this.dataQuery.where['com_sparepart_orders.id_com_location'] = this.filterParams.id_com_location
      }
      if (this.filterParams.id_com_department) {
        this.dataQuery.where['com_sparepart_orders.id_com_department'] = this.filterParams.id_com_department
      }
      this.$store.dispatch('sparepartOrders/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('sparepartOrders/deleteData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
