<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row class="pb-2">
          <b-col
            cols="12"
            class="d-flex justify-content-between"
          >
            <b-button
              variant="success"
              target="_blank"
              @click="excelModal"
            >
              <FeatherIcon icon="DownloadIcon" /> Excel İndir
            </b-button>
            <b-button
              variant="danger"
              :to="$route.path + '/search'"
            >
              <FeatherIcon icon="PlusIcon" /> Sipariş Oluştur
            </b-button>
          </b-col>
        </b-row>
        <b-tabs
          pills
          justified
        >
          <b-tab
            active
            lazy
          >
            <template #title>
              <feather-icon icon="PaperclipIcon" />
              <span>İş Emri Listesi</span>
            </template>
            <order-index />
          </b-tab>
          <b-tab
            lazy
          >
            <template #title>
              <feather-icon icon="SettingsIcon" />
              <span>Parça Listesi</span>
            </template>
            <part-index />
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
    <b-modal
      v-model="modalData.status"
      title="Tarihler Arası Filtrele"
      centered
      no-close-on-backdrop
    >
      <b-form-group
        label="Başlangıç Tarihi"
        label-for="status_sdate"
      >
        <b-form-datepicker
          v-model="modalData.order_sdate"
          v-bind="{labelNoDateSelected: 'Başlangıç Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
      <b-form-group
        label="Bitiş Tarihi"
        label-for="status_edate"
      >
        <b-form-datepicker
          v-model="modalData.order_edate"
          v-bind="{labelNoDateSelected: 'Bitiş Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="modalData.status = false"
        >
          <feather-icon icon="XIcon" /> İptal
        </b-button>
        <b-button
          variant="success"
          :href="downloadURL"
          target="_blank"
        >
          <feather-icon
            icon="DownloadIcon"
          /> Excel İndir
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BButton, BRow, BCol, BTabs, BTab, BFormDatepicker, BFormGroup, BModal,
} from 'bootstrap-vue'
import OrderIndex from '@/views/Spareparts/Orders/Index/OrderIndex.vue'
import PartIndex from '@/views/Spareparts/Orders/Index/PartIndex.vue'

export default {
  name: 'Index',
  components: {
    BModal,
    BFormGroup,
    BFormDatepicker,
    BCard,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    OrderIndex,
    PartIndex,
  },
  data() {
    return {
      modalData: {
        status: false,
        order_sdate: null,
        order_edate: null,
      },
    }
  },
  computed: {
    downloadURL() {
      const urlParams = []
      if (this.modalData.order_sdate) {
        urlParams.push(`order_sdate=${this.modalData.order_sdate}`)
      }
      if (this.modalData.order_edate) {
        urlParams.push(`order_edate=${this.modalData.order_edate}`)
      }
      const params = urlParams.join('&')

      let url = `${this.$store.state.app.baseURL}/exports/spareparts/orders/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  methods: {
    excelModal() {
      this.modalData.status = true
      this.modalData.order_sdate = null
      this.modalData.order_edate = null
    },
  },
}
</script>
