<template>
  <div>
    <order-filter-search :get-data-list="getDataList" />
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      bordered
      small
    >
      <template #cell(part_no)="data">
        <div
          v-if="data.item.part_no"
          class="font-weight-bold text-primary"
        >
          {{ data.item.part_no }}
        </div>
        <div class="font-small-2 width-200 text-wrap">
          {{ data.item.title }}
        </div>
      </template>
      <template #cell(work_no)="data">
        <div v-if="data.item.work_no">
          <small class="text-warning font-weight-bold">{{ data.item.work_no }}</small>
        </div>
        <div>
          {{ data.item.company_name ? data.item.company_name : data.item.customer }}
        </div>
        <div>
          <b-badge
            variant="light-secondary"
            style="margin-right: 5px"
          >
            {{ data.item.location }}
          </b-badge>
          <b-badge variant="light-secondary">
            {{ data.item.department }}
          </b-badge>
        </div>
      </template>
      <template #cell(completed_date)="data">
        <div
          v-if="data.item.order_date"
          style="margin-bottom: 4px"
        >
          <b-badge variant="light-info w-100">
            <FeatherIcon icon="ShoppingCartIcon" />
            Sipariş:
            {{ moment(data.item.order_date).format('DD.MM.YYYY') }}
          </b-badge>
        </div>
        <div
          v-if="data.item.completed_date"
          style="margin-bottom: 4px"
        >
          <b-badge variant="light-success w-100">
            <FeatherIcon icon="CheckIcon" />
            Geliş:
            {{ moment(data.item.completed_date).format('DD.MM.YYYY') }}
          </b-badge>
        </div>
        <div
          v-else
          style="margin-bottom: 4px"
        >
          <b-badge variant="light-warning w-100">
            <FeatherIcon icon="ClockIcon" />
            Tahmini Geliş:
            {{ moment(data.item.arrival_date).format('DD.MM.YYYY') }}
          </b-badge>
        </div>
      </template>
      <template #cell(username)="data">
        {{ data.item.username }}
        <div
          v-if="data.item.created"
          class="d-flex justify-content-startcenter align-items-center"
        >
          <small class="text-muted">
            <feather-icon icon="SaveIcon" />
            {{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}
          </small>
        </div>
        <div
          v-if="data.item.modified"
          class="d-flex justify-content-start align-items-center"
        >
          <small class="text-warning">
            <feather-icon icon="EditIcon" />
            {{ moment(data.item.modified).format('DD.MM.YYYY HH:mm:ss') }}
          </small>
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            :to="$route.path + '/edit/' + data.item.id"
            variant="outline-primary"
            size="sm"
            class="mr-1"
          >
            <feather-icon icon="SearchIcon" />
            Görüntüle
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </div>
</template>
<script>
import {
  BButton, BCardFooter, BPagination, BTable, BBadge,
} from 'bootstrap-vue'
import OrderFilterSearch from '@/views/Spareparts/Orders/Index/OrderFilterSearch.vue'

export default {
  name: 'OrderIndex',
  components: {
    OrderFilterSearch,
    BCardFooter,
    BButton,
    BTable,
    BPagination,
    BBadge,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'part_no',
          label: 'Parça No',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'work_no',
          label: 'İş Emri No / Müşteri',
        },
        {
          key: 'brand',
          label: 'Marka',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'license_plate',
          label: 'Plaka',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'completed_date',
          label: 'TARİH',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'username',
          label: 'Danışman',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'com_sparepart_orders.id AS id',
          'com_sparepart_stock_cards.part_no AS part_no',
          'com_sparepart_stock_cards.title AS title',
          'com_sparepart_orders.work_no AS work_no',
          'com_sparepart_order_lines.created AS created',
          'com_sparepart_order_lines.modified AS modified',
          'com_sparepart_order_lines.order_date AS order_date',
          'com_sparepart_order_lines.arrival_date AS arrival_date',
          'com_sparepart_order_lines.completed_date AS completed_date',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_cars.license_plate AS license_plate',
          'com_brand.name AS brand',
          'com_department.title AS department',
          'com_location.title AS location',
          'com_user.name AS username',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['sparepartOrderLines/dataList']
    },
    dataCounts() {
      return this.$store.getters['sparepartOrderLines/dataCounts']
    },
    filterParams() {
      return this.$store.getters['sparepartOrderLines/filterParams']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.dataQuery.where = {}
      this.dataQuery.or_like = {}
      if (this.filterParams.keyword) {
        this.dataQuery.or_like = {
          'com_sparepart_order_lines.order_no': this.filterParams.keyword,
          'com_cars.license_plate': this.filterParams.keyword,
          'com_customer.name': this.filterParams.keyword,
          'com_customer.company_name': this.filterParams.keyword,
        }
      }
      if (this.filterParams.id_com_brand) {
        this.dataQuery.where['com_sparepart_orders.id_com_brand'] = this.filterParams.id_com_brand
      }
      if (this.filterParams.id_com_sparepart_order_type) {
        this.dataQuery.where['com_sparepart_orders.id_com_sparepart_order_type'] = this.filterParams.id_com_sparepart_order_type
      }
      if (this.filterParams.id_com_location) {
        this.dataQuery.where['com_sparepart_orders.id_com_location'] = this.filterParams.id_com_location
      }
      if (this.filterParams.id_com_department) {
        this.dataQuery.where['com_sparepart_orders.id_com_department'] = this.filterParams.id_com_department
      }
      if (this.filterParams.order_sdate) {
        this.dataQuery.where['com_sparepart_order_lines.order_date >='] = this.filterParams.order_sdate
      }
      if (this.filterParams.order_edate) {
        this.dataQuery.where['com_sparepart_order_lines.order_date<='] = this.filterParams.order_edate
      }
      if (this.filterParams.arrival_sdate) {
        this.dataQuery.where['com_sparepart_order_lines.arrival_date>='] = this.filterParams.arrival_sdate
      }
      if (this.filterParams.arrival_edate) {
        this.dataQuery.where['com_sparepart_order_lines.arrival_date<='] = this.filterParams.arrival_edate
      }
      if (this.filterParams.completed_sdate) {
        this.dataQuery.where['com_sparepart_order_lines.completed_date>='] = this.filterParams.completed_sdate
      }
      if (this.filterParams.completed_edate) {
        this.dataQuery.where['com_sparepart_order_lines.completed_date<='] = this.filterParams.completed_edate
      }
      this.$store.dispatch('sparepartOrderLines/getDataList', this.dataQuery)
    },
  },
}
</script>
